import { Injectable } from '@angular/core';
import {Restangular} from "ngx-restangular";

@Injectable({providedIn: 'root'})
export class UserManagementService {

  constructor(private restangular:Restangular) { }

  //Users/////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getUserList(data){
    return this.restangular.one("users/userList").customPOST(data);
  }

  updateCredential(credential) {
    return this.restangular.one('users/reset-password').customPOST(credential);
  }

  getAllUsers(){
    return this.restangular.one("users/getAllUsers").customPOST();
  }

  getUserDetails(id){
    return this.restangular.one("users").customGET("/userDetails",{id:id});
  }

  saveUserDetails(data){
    data.authorities = [];
    if(data.id){
      return this.restangular.one("users/editUser").customPOST(data);
    }else {
      return this.restangular.one("users/createUser").customPOST(data);
    }
  }

  //Roles////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getAllRoles(){
    return this.restangular.one("roles/all").customGET();
  }

  getRoleList(data){
    return this.restangular.one("roles/roleList").customPOST(data);
  }

  saveRoleDetails(data){
    if(data.roleDetails.id){
      return this.restangular.one("roles/editRole").customPOST(data);
    }else {
      return this.restangular.one("roles/createRole").customPOST(data);
    }
  }

  //Permisisons///////////////////////////////////////////////////////////////////////////////////////////////////////
  getAllPermissions(){
    return this.restangular.one("permissions/all").customGET();
  }

  getPermissionsList(data){
    return this.restangular.one("permissions/permissionList").customPOST(data);
  }

  savePermissionDetails(data){
    if(data.permissionDetails.id){
      return this.restangular.one("permissions/editPermission").customPOST(data);
    }else {
      return this.restangular.one("permissions/createPermission").customPOST(data);
    }
  }


  //branches and depatment///////////////////////////////////////////////////////////////////////////////////////////
  getBranches() {
    return this.restangular.one("branch/all").customGET();
  }

  getDepartments() {
    return this.restangular.one("department/all").customGET();
  }

  getModules(){
    return this.restangular.one("module/all").customGET();
  }

//  //////////////////////////////////////////////////////////////////////////

  getUsers() {
    return this.restangular.one("users").customGET();
  }

}
