import { Injectable } from '@angular/core';
import {Restangular} from "ngx-restangular";

@Injectable({providedIn: 'root'})
export class UtilService {

  constructor(private restangular:Restangular) {}

  sasTokenForBlob() {
    return this.restangular.one('util/azure/sas-token-for-blob').customGET();
  }
}
