import {Injectable} from '@angular/core';
import {Restangular} from "ngx-restangular";

@Injectable({providedIn: 'root'})
export class PurchaseInvoiceService {

  constructor(private restangular: Restangular) {
  }

  savePurchaseInvoice(data) {
    if(data.id){
      return this.restangular.one("purchase-invoices").customPUT(data, data.id);
    }else{
      return this.restangular.one("purchase-invoices").customPOST(data);
    }
  }

  getPurchaseInvoiceList(data) {
    return this.restangular.one("purchase-invoices/list").customPOST(data);
  }

  purchaseInvoiceDetails(id) {
    return this.restangular.one("purchase-invoices").customGET(id);
  }

  showPurchaseOrder(id) {
    return this.restangular.one("purchase-invoices").customGET("purchase-order-for-purchase-invoice", {purchaseOrderId:id});
  }

  getPurchaseInvoiceListMetaInfo(data) {
    return this.restangular.one("purchase-invoices/list-meta-info").customPOST(data);
  }

  purchaseInvoiceListExcelDownload(data) {
    return this.restangular.one('purchase-invoices/list-excel-download').withHttpConfig({responseType: 'blob'}).customPOST(data);
  }

  exportPI(id){
    this.restangular.one('purchase-invoices/download-pdf').withHttpConfig({responseType: "blob"}).customGET(id).subscribe(response => {
      console.log(response);
      let url = (window.URL).createObjectURL(response);
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);//required in FF, optional for Chrome
      anchor.download = "PI-"+ id +".pdf";
      anchor.href = url;
      anchor.click();
    },err => console.log(err));
  }

  purchaseInvoiceListForSettlement(data) {
    return this.restangular.one("purchase-invoices/purchase-invoice-list-for-settlement").customPOST(data);
  }
}
